// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: white;
    color: #797979;
    padding: 40px;
    text-align: start;
    font-size: 14px;
    width: 100%;
    border-top: 1px solid rgba(233,233,233,0.7);
    box-sizing: border-box;
    margin-top: 20%;
}

.footer-content {
    padding: 0 40px;
}

.footer h2 {
    margin-bottom: 10px;
}

.footer p {
    margin: 5px 0;
}

@media (max-width: 800px) {
    .footer { font-size: 12px; }
}

@media (max-width: 500px) {
    .footer { font-size: 9px; }
}`, "",{"version":3,"sources":["webpack://./src/style/footer.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,2CAA2C;IAC3C,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU,eAAe,EAAE;AAC/B;;AAEA;IACI,UAAU,cAAc,EAAE;AAC9B","sourcesContent":[".footer {\n    background-color: white;\n    color: #797979;\n    padding: 40px;\n    text-align: start;\n    font-size: 14px;\n    width: 100%;\n    border-top: 1px solid rgba(233,233,233,0.7);\n    box-sizing: border-box;\n    margin-top: 20%;\n}\n\n.footer-content {\n    padding: 0 40px;\n}\n\n.footer h2 {\n    margin-bottom: 10px;\n}\n\n.footer p {\n    margin: 5px 0;\n}\n\n@media (max-width: 800px) {\n    .footer { font-size: 12px; }\n}\n\n@media (max-width: 500px) {\n    .footer { font-size: 9px; }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
