import logo from './assets/header_logo.svg';
import phone from './assets/phone.svg';
import apart from './assets/apart.svg';
import logoRed from './assets/logo_red.svg';
import qr from './assets/qr.png';
import google from './assets/playstore.png';
import apple from './assets/appstore.png';
import './App.css';
import styled from "styled-components";
import Footer from "./components/footer";

function App() {
  return (
    <Container>
        <Header>
            <img src={logo} alt=""/>
        </Header>
        <BannerContainer>
            <img src={phone} alt=""/>
            <p>
                당당하고 솔직한<br/>
                주거생활 커뮤니티
            </p>
            <ImageContainer src={apart} alt=""/>
        </BannerContainer>
        <BannerContainer2>
            <p>
                진실한 소통을 바탕으로<br/>고객의 요구를 정확히 이해하고,<br/>혁신적인 솔루션을 제공하고 있습니다.
            </p>
        </BannerContainer2>
        <img width="50%" src={phone} alt=""/>
        <BannerContainer3>
            <p>
                우리 회사는 아파트 익명 커뮤니티 앱을 개발하는 기업으로서,<br/>
                이웃 간의 소통과 연결을 촉진하는 혁신적인 서비스를 제공합니다.<br/>
                저희의 앱은 고객들에게 안전하고 익명성을 보장하면서도,<br/>
                이웃들끼리 솔직하게 소통하고 정보를 공유할 수 있는 플랫폼을 제공합니다.<br/><br/>
                우리의 앱은 이웃들 간의 거리를 좁히고, 사회적 관계를 촉진하며,<br/>
                아파트 커뮤니티를 더욱 활기차게 만들어줍니다.<br/>
                이를 통해 주민들은 생활 정보를 쉽게 교환하고,<br/>
                공동 문제를 해결하며, 서로의 요구와 관심사를 이해할 수 있습니다.<br/><br/>
                또한, 저희의 앱은 사용자들이 신원을 숨기고 자유롭게 의견을 나눌 수 있는<br/>
                안전한 공간을 제공하여, 이웃들 간의 소통이 더욱 활발하고 솔직해질 수 있도록 돕고 있습니다.<br/>
                우리 회사는 이러한 소통과 연결을 통해 더 나은 아파트 생활을 만들어가는 데 전념하고 있습니다.<br/>
            </p>
        </BannerContainer3>
        <BannerContainer4>
            <img width="30%" src={logoRed} alt=""/>
            <p>어플 설치하러 가기</p>
            <img width="30%" src={qr} alt=""/>
            <LinkContainer>
                <a href="https://play.google.com/store/apps/details?id=macha.aptdam_client&pcampaignid=web_share">
                    <AppleImg src={apple} alt=""/>
                </a>
                <a href="https://apps.apple.com/kr/app/apt%EB%8B%B4%ED%99%94/id6475274026">
                    <GoogleImg src={google} alt=""/>
                </a>
            </LinkContainer>
        </BannerContainer4>
        <Footer />
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #D51515;
  box-sizing: border-box;
  padding: 10px 20px;
  > img {
    width: 100px;
  }
`;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50vw;
  background-color: #D51515;
  box-sizing: border-box;
  padding: 10px 20px;
  justify-content: center;
  gap: 20%;
  position: relative;
  > p { color: white; margin-bottom: 80px;  font-weight: 700; font-size: 30px; }
  > img {
    width: 25%;
  }
  @media (max-width: 800px) {
    > p { font-size: 24px; }
  }
`;
const BannerContainer2 = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
  box-sizing: border-box;
  
  position: relative;
  margin: 80px 0;
  > p { font-weight: 900; font-size: 40px; text-align: center; color: #D51515; }
  @media (max-width: 800px) {
    > p { font-size: 30px; }
  }
  @media (max-width: 500px) {
    > p { font-size: 20px; }
  }
`;

const BannerContainer3 = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
  box-sizing: border-box;
  
  position: relative;
  margin: 80px 0;
  > p { font-weight: 600; font-size: 20px; text-align: center; }
  @media (max-width: 800px) {
    > p { font-size: 15px; }
  }
  @media (max-width: 500px) {
    > p { font-size: 10px; }
  }
`;
const BannerContainer4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  justify-content: center;
  gap: 30px;
  box-sizing: border-box;
  position: relative;
  margin: 80px 0;
  > p { font-weight: 900; font-size: 40px; text-align: center; color: black; }
  @media (max-width: 800px) {
    > p { font-size: 30px; }
  }
  @media (max-width: 500px) {
    > p { font-size: 20px; }
  }
`;
const ImageContainer = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50% !important;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const AppleImg = styled.img`
  width: 200px;
  @media (max-width: 600px) {
    width: 150px;
  }
`;

const GoogleImg = styled.img`
  width: 160px;
  @media (max-width: 600px) {
    width: 120px;
  }
`;
