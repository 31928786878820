import React from 'react';
import '../style/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>법인명(상호) : 주식회사 에프티</p>
                <p>대표자(성명) : Ryan Seo</p>
                <p>사업자 등록번호 : 896-88-02824</p>
                <p>사업장 주소 : 경기도 용인시 기흥구 강남로 9, 5층 503-504호(구갈동, 태평양프라자)</p>
                <p>이메일: support@aptdamhwa.com</p>
            </div>
        </footer>
    );
};

export default Footer;
